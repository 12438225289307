@import "../base/variables";
@import "../base/mixins";

section.cms-intro-row {
    padding-top: 40px;
    margin-bottom: 140px;

    .breadcrumbs {
        padding: 0;

        strong {
            color: $greyDarkest;
        }

        a:hover {
            color: $blue;
        }
    }
}