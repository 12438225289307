section.cms-intro-row {
  padding-top: 40px;
  margin-bottom: 140px;
}
section.cms-intro-row .breadcrumbs {
  padding: 0;
}
section.cms-intro-row .breadcrumbs strong {
  color: #333333;
}
section.cms-intro-row .breadcrumbs a:hover {
  color: #0087D2;
}